import * as React from 'react'
import styles from './style.module.css'
import ContentfulRichText from 'components/rich-text'
import Link from 'next/link'
import useScreenExitDetection from 'hooks/useScreenExitDetection'
import { DIRECTION_VERTICAL } from 'enum/direction'
import Loader from 'components/loading/loader'
import { BLOCKS } from '@contentful/rich-text-types'
import { useEmailCapturePopupContext } from 'context/email-capture-popup-context'
import { IPageSectionHero } from 'interfaces/page-section-hero'
import HeroImage from 'components/hero-image'
import { IUiResources } from 'interfaces/ui-resource'
import dynamic from 'next/dynamic'

interface IProps {
    pageSectionHero: IPageSectionHero
    internalName?: string
    uiResources?: IUiResources
}

const headerRenderOptions = {
    renderNode: {
        // eslint-disable-next-line react/display-name
        [BLOCKS.PARAGRAPH]: (_node, children): React.ReactElement => (
            // set leading in every breakpoint that is used to define text-{size}
            // this is because text-{size} defines it's onw line height
            <h1 className="section--header hero--header">{children}</h1>
        ),
    },
}

const subHeaderRenderOptions = {
    renderNode: {
        // eslint-disable-next-line react/display-name
        [BLOCKS.PARAGRAPH]: (_node, children): React.ReactElement => (
            <span className="section--subheader lg:text-xl block lg:leading-120%">{children}</span>
        ),
    },
}

const paragraphRenderOptions = {
    renderNode: {
        // eslint-disable-next-line react/display-name
        [BLOCKS.PARAGRAPH]: (_node, children): React.ReactElement => <p className="section--paragraph">{children}</p>,
    },
}

const StoreLocatorMap = dynamic(() => import('components/store-locator/store-locator-map'), {
    /* eslint-disable react/display-name */
    loading: () => <Loader />,
})

export const HeroSectionWithCTA = ({ pageSectionHero, internalName, uiResources }: IProps): React.ReactElement => {
    const {
        backgroundImagesCollection,
        mobileBackgroundImagesCollection,
        richHeader,
        richSubHeader,
        paragraphCopy,
        primaryCtaLink,
        secondaryCtaLink,
        renderType,
    } = pageSectionHero ?? {}

    const [isRedirecting, setIsRedirecting] = React.useState(false)

    const [, forceRerender] = React.useState({})

    const sectionRef = React.useRef<HTMLElement>()
    const floatingElRef = React.useRef<HTMLDivElement>()

    const hasHeroSectionExitedViewport = useScreenExitDetection({
        element: sectionRef.current,
        direction: DIRECTION_VERTICAL.TOP,
    })

    const { openPopup } = useEmailCapturePopupContext()

    React.useEffect(() => {
        // force update component so that the useScreenExitDetection
        // is passed sectionRef as element, on initial render sectionRef
        // will be undefined
        forceRerender({})
    }, [])

    React.useEffect(() => {
        if (hasHeroSectionExitedViewport === false) {
            document.documentElement.style.setProperty('--fixedToBottomElementHeight', '0px')
        } else {
            if (floatingElRef.current) {
                const floatingElHeight = floatingElRef.current?.clientHeight

                document.documentElement.style.setProperty('--fixedToBottomElementHeight', floatingElHeight + 'px')
            }
        }

        return () => {
            document.documentElement.style.setProperty('--fixedToBottomElementHeight', '0px')
        }
    }, [hasHeroSectionExitedViewport])

    React.useEffect(() => {
        if (typeof openPopup === 'function' && hasHeroSectionExitedViewport) {
            openPopup()
        }
    }, [hasHeroSectionExitedViewport, openPopup])

    const centerContent = renderType === 'content center aligned'

    const hasHeroImage = backgroundImagesCollection?.items?.[0] || mobileBackgroundImagesCollection?.items?.[0]

    const renderStoreLocator = () => {
        if (internalName === 'Store Locator' || internalName === 'Store Locator - Aisle') {
            return (
                <div className="mt-10 sm:ml-24">
                    <StoreLocatorMap uiResources={uiResources} />
                </div>
            )
        }
    }

    return (
        <>
            <section
                ref={sectionRef}
                className={`${styles['hero-section']} ${
                    styles['hero-section--with-icons-section']
                } flex relative overflow-hidden ${centerContent ? 'justify-center' : ''}`}
            >
                {/* hero image */}
                {hasHeroImage && (
                    <HeroImage
                        className="w-full h-full object-cover absolute top-0 left-0"
                        backgroundImage={backgroundImagesCollection?.items?.[0]}
                        mobileBackgroundImage={mobileBackgroundImagesCollection?.items?.[0]}
                    />
                )}
                {/* hero content */}
                <div
                    className={`${
                        hasHeroImage
                            ? `text-white px-5 pb-7 pt-20 lg:px-16 lg:pt-[84px] flex flex-col ${
                                  centerContent
                                      ? 'text-center items-center'
                                      : 'justify-between lg:justify-start w-full sm:w-auto'
                              } relative z-10 ${styles['hero-content']}`
                            : 'pb-7 flex flex-col text-black lg:px-16 pt-8 lg:pt-[40px] z-20'
                    }`}
                >
                    <div>
                        <ContentfulRichText content={richHeader?.json} renderOptions={headerRenderOptions} />
                        <div className={`${hasHeroImage ? 'hidden lg:block' : ''}`}>
                            {richSubHeader?.json && (
                                <div className={`${hasHeroImage ? 'mt-5' : 'mt-2'}`}>
                                    <ContentfulRichText
                                        content={richSubHeader.json}
                                        renderOptions={subHeaderRenderOptions}
                                    />
                                </div>
                            )}
                            {paragraphCopy?.json && (
                                <div className="hidden lg:block mt-1.5">
                                    <ContentfulRichText
                                        content={paragraphCopy?.json}
                                        renderOptions={paragraphRenderOptions}
                                    />
                                </div>
                            )}
                        </div>
                    </div>

                    {/* store locator */}
                    {renderStoreLocator()}

                    {/* CTAs */}
                    {(primaryCtaLink?.href || secondaryCtaLink?.href) && (
                        <div className="flex flex-col mt-2.5 lg:mt-7.5">
                            {/* primary CTA */}
                            {primaryCtaLink?.href && (
                                <Link
                                    href={primaryCtaLink.href}
                                    onClick={() => setIsRedirecting(true)}
                                    className={`button button-secondary section--primary-cta px-12 border-white no-hover text-black bg-white group ${
                                        centerContent ? 'min-w-250' : 'block sm:self-start'
                                    }`}
                                >
                                    {isRedirecting ? <Loader secondary /> : primaryCtaLink?.name}
                                </Link>
                            )}
                            {/* secondary CTA */}
                            {secondaryCtaLink?.href && (
                                <Link
                                    href={secondaryCtaLink?.href}
                                    className={`button button-plain text-sm text-white mt-1.5 ${
                                        centerContent ? '' : 'text-left px-0'
                                    }`}
                                >
                                    {secondaryCtaLink?.name}
                                </Link>
                            )}
                        </div>
                    )}
                </div>
            </section>
        </>
    )
}
